import Navbar from './Navbar.js';
import Headroom from 'headroom.js';

export function init() {
  const navbar = document.querySelector('[data-navbar]');

  if (!navbar) return;

  // headroom js
  const headroom = new Headroom(navbar, {
    offset: 150,
    classes: {
      initial: "has-headroom",
      pinned: "is-pinned",
      unpinned: "is-unpinned",
      top: "is-top",
      notTop: "is-not-top",
      bottom: "is-bottom",
      notBottom: "is-not-bottom",
      frozen: "is-frozen",
    },
  });
  headroom.init();

  // navbar
  new Navbar(navbar);
}
