import domReady from '@roots/sage/client/dom-ready';

import * as Navbar from './modules/Navbar/index.js';

/**
 * Main
 */
const main = async (err) => {
  if (err) {
    console.error(err);
  }

  const modules = [Navbar];

  for (const module of modules) {
    if (!module.init) return;

    await module.init();
  }
};


/**
 * Initialize
 */
domReady(main);

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);
