import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger.js';

gsap.registerPlugin(ScrollTrigger);

export default class Navbar {
  constructor(node) {

    // Bind constants to class
    this.node = node;
    this.collapse = node.querySelector('[data-navbar-collapse]');
    this.collapseItems = node.querySelectorAll('[data-navbar-collapse-item]');
    this.toggle = node.querySelector('[data-navbar-toggle]');
    this.backdrop = document.querySelector('[data-navbar-backdrop]');


    // Initial states
    this.active = false;
    this.isTransforming = false;
    this.breakpoint = 1024;
    this.timeline = this.setTimeline();

    // Initial bind for performance
    this.handleClick = this.handleClick.bind(this);

    // Set events
    this.toggle.addEventListener('click', this.handleClick);
    this.backdrop.addEventListener('click', this.handleClick);


    this.handleScroll();
  }

  handleScroll() {
    ScrollTrigger.create({
      start: 'top -80',
      end: 99999,
      toggleClass: {
        className: 'has-scrolled',
        targets: this.node,
      },
    });
  }

  setTimeline() {
    const timeline = gsap.timeline({
      paused: true,
      onStart: () => {
        document.body.classList.add('overflow-hidden');
        this.node.classList.add('is-open');
        this.isTransforming = true;
      },
      onComplete: () => {
        this.isTransforming = false;

      },
      onReverseComplete: () => {
        document.body.classList.remove('overflow-hidden');
        this.isTransforming = false;
      },
    });

    let mm = gsap.matchMedia();

    mm.add(`(max-width: ${this.breakpoint - 1}px)`, () => {
      // default states
      gsap.set(this.backdrop, {
        opacity: 0,
        display: 'none',
      });

      gsap.set(this.collapse, {
        translateX: '100%',
      });

      gsap.set(this.collapseItems, {
        clipPath: 'inset(0% 0% 100% 0%)',
        translateY: '100%',
      })

      // backdrop
      timeline.to(this.backdrop, {
        opacity: 0.15,
        display: 'block',
        duration: 0.4,
        ease: 'Power4.easeOut',
      });

      // collapse
      timeline.to(this.collapse, {
        translateX: '0',
        duration: 0.4,
        ease: 'Power4.easeOut',
      }, "<");

      // collapse items
      timeline.to(this.collapseItems, {
        clipPath: 'inset(0% 0% 0% 0%)',
        translateY: '0%',
        duration: 0.2,
        stagger: 0.05,
        ease: 'Power4.easeOut',
      });
    });

    return timeline;
  }

  handleClick(e) {
    if (e) {
      e.preventDefault();
    }

    // Update ARIA attributes
    this.collapse.setAttribute('aria-expanded', !this.active);

    this.play();
  }

  play() {
    if (this.isTransforming) return;

    if (this.active) {
      this.timeline.reverse();
      this.node.classList.remove('is-open');
      this.isTransforming = true;
    } else {
      this.timeline.play();
    }
    this.active = !this.active;
  }
}
